import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import toparrow from "../../image/newImg/toparrow.png";
import SideBar from "../SideBar/SideBar";
import "./Content.css";
import "./Results.css";
// import { Col, Row } from "react-bootstrap";
// import r10 from "../../image/r10/result-10.jpg";
// import r12 from "../../image/r10/result-12.jpg";
// import r122 from "../../image/r10/result-12-2.jpg";

import board from "../../image/board.jpg";
// import board from "../../image/bright10.jpg";
import board12 from "../../image/board12.jpg";
// import board12 from "../../image/bright12.jpg";

const x12th = require("../../excel/bhimtal12.xlsx");
const x10th = require("../../excel/bhimtal10.xlsx");
const res = require("../../excel/Results of all schools - website upload.xlsx");

function Results(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              className="admissions__image"
              src={toparrow}
              style={{ marginRight: "1rem" }}
              alt=""
            />{" "}
            Results
          </div>

          <div className="row">
            <div className="col-lg-5">
              <div className="result-btn mt-2">
                <a href={x10th}>
                  <button className="tenth_result">
                    VIEW 10 YEARS’ ANALYSIS RESULT (X)
                  </button>
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="result-btn mt-2">
                <a href={x12th}>
                  <button className="tenth_result">
                    VIEW 10 YEARS’ ANALYSIS RESULT (XII)
                  </button>
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="result-btn mt-2">
                <a href={res}>
                  <button className="tenth_result">
                    Board Result Class 10 and 12
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="mt-4">
            Class X and XII examinations are considered very important in a
            student’s school life. The school must provide proper guidance and
            support to children for these examinations. Our students have always
            made us proud with their diligence and hard work. The school has a
            100% pass percentage in both the classes. Our school has had a 100%
            pass percentage for the last three years. This is possible because
            of the strong focus on academics and the meaningful guidance of our
            teachers. With the mentorship of teachers and the grit of students,
            our school provides a perfect ambiance for anyone determined to
            learn. The result and performance show the tremendous hard work of
            students.
          </div>

          <div className="my-4">
            <h2>Class Xth Result-</h2>

            <div className="img my-2">
              <img
                className="my-2"
                style={{ height: "450px", width: "100%" }}
                src={board}
                alt=""
              />
            </div>
          </div>
          <div className="my-4">
            <h2>Class XIIth Result-</h2>

            <div className="img my-2">
              <img
                className="my-2"
                style={{ height: "450px", width: "100%" }}
                src={board12}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Results;
