import React from "react";
import { NavLink } from "react-router-dom";
import background1 from "../../image/Website_Banner.png";
import background2 from "../../image/boardingHome.jpg";
import "./Inspired.css";
function Inspired({ heading, desc, btnText, btnNav, headingColor }) {
  let pic = "";
  heading === "Illustrious Alumni" ? (pic = background1) : (pic = background2);
  return (
    <div className="inspired_main" style={{ backgroundImage: `url(${pic})` }}>
      {heading === "Illustrious Alumni" ? (
        <div
          className="f-3 color-white f-bold inspired_title"
          style={{ color: `${headingColor}` }}
        >
          {heading}
        </div>
      ) : (
        <div
          className="f-3 color-white f-bold inspired_title"
          style={{
            color: `${headingColor}`,
            textShadow: "6px 4px 0px #000000",
          }}
        >
          {heading}
        </div>
      )}
      <div className="f-1-2 color-white f-500 mt-2 inspired_subtitle">
        {desc}
      </div>
      {/* <div className='f-1-5 color-white f-bold mt-2'>Explore notable student, faculty and alumni stories from across the globe!</div> */}
      <div className="inspired_button">
        <NavLink to={`/${btnNav}`}>
          <button className="btn read_more color-white f-500 f-1-1">
            {btnText}
          </button>
        </NavLink>
      </div>
    </div>
  );
}

export default Inspired;
