// import React, { useEffect, useState } from "react";

import React from "react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import NavbarLogo from "../../image/icon/navbar-logo.png";
// import MainLogo from "../images/sos_logo.png";
import InstagramLogoLarge from "../../image/icon/instagram-logo-new.png";
import TwitterLogoLarge from "../../image/icon/twitter-logo-new.png";
import FacebookLogoLarge from "../../image/icon/facebook-logo-new.png";
import linkedin from "../../image/icon/linkedin.png";
import youtube from "../../image/icon/youtube.png";

import "./NewNavbar.css";

const NewNavbar = (props) => {
  const { handleClick } = props;
  const [navbarHide, setNavbarHide] = useState(true);
  const handleKnowMore = () => {
    handleClick();
  };

  const toggleNavbar = () => {
    console.log("toggleNavbar");
    setNavbarHide(!navbarHide);
  };

  return (
    <div className="navbarwrapper">
      <h6 className="d-none d-md-block mb-0">
        HERMANN GMEINER SCHOOL, BHIMTAL
      </h6>
      <div className="navbarContainer">
        <div data-aos="fade" className="navbarLarge">
          {/* <Link to="/home" className="h-14">
          <img className="h-full" src={MainLogo} alt="SOS" />
        </Link> */}
          <div
            className="d-flex d-lg-none justify-content-center align-items-center"
            onClick={toggleNavbar}
          >
            <h6 className="d-block d-md-none">
              HERMANN GMEINER SCHOOL, BHIMTAL
            </h6>
            <div className="mobileToggleIcon">
              <img className="h-100" src={NavbarLogo} alt="" />
            </div>
          </div>
          <div className="navbarItems">
            <Link to="/" className="navbarItem">
              Home
            </Link>
            <span>|</span>
            <Link to="/mandatory-disclosures" className="navbarItem">
              Mandatory Disclosure
            </Link>
            <span>|</span>
            <Link to="/Fees-payment" className="navbarItem">
              Fees-Payment
            </Link>
            <span>|</span>
            <Link to="/parent-login" className="navbarItem">
              Parent Login
            </Link>
            <span>|</span>
            <Link to="/student-login" className="navbarItem">
              Students Login
            </Link>
            <span>|</span>
            <Link to="/blogs" className="navbarItem">
              Blogs
            </Link>
            <span>|</span>
            <p onClick={handleKnowMore} className="navbarItem mb-0 ">
              Know More
            </p>
            <span>|</span>
            <Link to="/contact-us" className="navbarItem">
              Contact Us
            </Link>
            <span>|</span>
            <div className="d-flex">
              <div className="socialIconLarge d-flex">
                <img
                  className="h-100"
                  src={FacebookLogoLarge}
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/HGSBHIMTAL1984",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className="socialIconLarge d-flex">
                <img
                  className="h-100"
                  src={InstagramLogoLarge}
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/hermanngmeinerschoolbhimtal/",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className="socialIconLarge d-flex">
                <img
                  className="h-100"
                  src={TwitterLogoLarge}
                  alt=""
                  onClick={() =>
                    window.open("https://twitter.com/GmeinerHerman", "_blank")
                  }
                />
              </div>
              <div className="socialIconLarge d-flex me-0">
                <img
                  className="h-100"
                  src={youtube}
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/results?search_query=hermann+gmeiner+school+bhimtal",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "navbarSmall" + (navbarHide ? " custom-right-full" : " end-0")
        }
      >
        <div className="mobileToggle" onClick={toggleNavbar}></div>
        <div className="navMobileItems">
          <Link to="/" className="navMobileItem" onClick={toggleNavbar}>
            Home
          </Link>
          <Link
            to="/mandatory-disclosures"
            className="navMobileItem"
            onClick={toggleNavbar}
          >
            Mandatory Disclosure
          </Link>
          <Link
            to="/parent-login"
            className="navMobileItem"
            onClick={toggleNavbar}
          >
            Parent Login
          </Link>
          <p onClick={handleKnowMore} className="navMobileItem">
            Know More
          </p>
          <Link
            to="/contact-us"
            className="navMobileItem"
            onClick={toggleNavbar}
          >
            Contact Us
          </Link>
          <div className="mobileSocialContainer">
            <div className="mobileSocialIcon">
              <img
                className="h-100"
                src={FacebookLogoLarge}
                alt=""
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/HGSBHIMTAL1984",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="mobileSocialIcon">
              <img
                className="h-100"
                src={InstagramLogoLarge}
                alt=""
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/hermanngmeinerschoolbhimtal/",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="mobileSocialIcon">
              <img
                className="h-100"
                src={TwitterLogoLarge}
                alt=""
                onClick={() =>
                  window.open("https://twitter.com/GmeinerHerman", "_blank")
                }
              />
            </div>
            <div className="mobileSocialIcon">
              <img
                className="h-100"
                src={youtube}
                alt=""
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/results?search_query=hermann+gmeiner+school+bhimtal",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewNavbar;
