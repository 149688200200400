// import React, { useState } from "react";
// import Contact from "../Contact/Contact";
// import Footer from "../Footer/Footer";
// import Landing from "../Landing/Landing";
// import Navbar from "../NavBar/Navbar";
// import NavbarMenu from "../NavbarMenu/NavbarMenu";
// import SideBar from "../SideBar/SideBar";
// import "./Content.css";
// import "./Achivement.css";
// import achTopIcon from "../../image/newImg/ach.png";
// import bullet_icon from "../../image/bullet_icon.svg";
// import award_1 from "../../image/award/award_1.jpg";
// import award_2 from "../../image/award/award_2.jpg";
// import award_3 from "../../image/award/award_3.jpg";
// import award_4 from "../../image/award/award_4.jpg";
// import award_5 from "../../image/award/award_5.jpg";
// import award_6 from "../../image/award/award_6.jpg";
// import award7 from "../../image/award/award7.jpg";

// // import award_7 from "../../image/award_7.jpg";
// // import award_8 from "../../image/award_8.jpg";
// // import award_9 from "../../image/award_9.jpg";
// // import award_10 from "../../image/award_10.jpg";
// import a1 from "../../image/newImg/a1.jpg";
// import a2 from "../../image/newImg/a2.jpg";
// import a3 from "../../image/newImg/a3.jpg";
// import a4 from "../../image/newImg/a4.jpg";
// import a5 from "../../image/newImg/a5.jpg";
// import a6 from "../../image/newImg/a6.jpg";
// import a7 from "../../image/newImg/a7.jpg";
// import a8 from "../../image/newImg/a8.jpg";
// import a9 from "../../image/newImg/a9.jpg";
// import a10 from "../../image/newImg/a10.png";
// import a11 from "../../image/newImg/a11.png";
// import a12 from "../../image/newImg/a12.jpg";

// import achievmentsimg from "../../image/achievments.jpg";
// import { Col, Row } from "react-bootstrap";
// function Achievements(props) {
//   const { cover, sidebar_data, side_heading, side_sub_heading } = props;
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isShow, steIsShow] = useState(false);
//   const handleScroll = (e) => {
//     console.log("asd");
//     if (e.target.scrollTop > 0 && !isScrolled) {
//       setIsScrolled(true);
//     }
//     if (e.target.scrollTop === 0) {
//       setIsScrolled(false);
//     }
//   };
//   const handleClick = () => {
//     steIsShow(!isShow);
//     console.log(isShow);
//   };

//   return (
//     <div className="content_main" onScroll={handleScroll}>
//       <Navbar isScrolled={isScrolled} handleClick={handleClick} />
//       <NavbarMenu isShow={isShow} handleClick={handleClick} />
//       <Landing video={false} cover={cover} />
//       <div className="contentmain_cont">
//         <div style={{ width: "min-content" }}>
//           <SideBar
//             sidebar_data={sidebar_data}
//             side_heading={side_heading}
//             side_sub_heading={side_sub_heading}
//           />
//           <Contact />
//         </div>
//         <div>
//           <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
//             <img className="admissions_image" src={achTopIcon} />
//             Achievements & Laurels
//           </div>
        

//           <div>
//             <div className="d-flex gap-2 mt-1">
//               <div>
//                 <div className="color-grey f-500">
//                   "The harder you work for something, the greater you'll feel
//                   when you achieve it." It is a jubilant & escatic moment for
//                   Herman Gmeiner School to announce that our school has been
//                   nominated for the Global Awards for its astounding innovation
//                   in Pedagogy.
//                 </div>
//                 <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
//                   <p style={{ fontSize: "1.5rem" }}>
//                     <b>School’s Achievements</b>
//                   </p>
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                       marginTop:"-22px"
//                     }}
//                   >
//                     <div style={{textAlign:"left",marginRight:"2%"}}>
//                       Take pride in how far we have come and have faith in how
//                       far we can go. Congratulations are in order!!
//                    A proud moment for Hermann Gmeiner School as the
//                       school has once again been awarded, and this time RANKED
                     
//                       No. 1 IN NAINITAL With a Certificate of Achievement in "
//                       INDIA’S TOP DAY CUM BOARDING SCHOOL”, by Education 
//                       Today. This award brings honor and opens the door to more
//                       success in the future. This is a much-deserved award
//                        for a school that believes in consistent hard work
//                       and pursues excellence. It is inspirational to witness
//                       hard work giving birth to success. The school has been
//                       recognized with such a prestigious award through the
//                       combined 
//                       efforts of the teachers and students. May we keep climbing
//                       new heights of success!
//                     </div>
//                     <div>
//                       <img
//                         style={{
//                           width: "350px",
//                           height: "200px",
//                           border: "3px solid rgba(31, 220, 0, 0.93)",
//                         }}
//                         src={award7}
//                       />
//                     </div>
//                   </div>
                  
//                 </div>

//                 <Col className="middlee">
//                   <Row style={{ marginTop: "2rem" }}>
//                     <Col
//                       style={{
//                         marginBottom: "2rem",
//                         display: "flex",
//                         justifyContent: "center",
//                       }}
//                     >
//                       <img className="awardimg" src={award_1} alt="" />
//                     </Col>
//                     <Col
//                       style={{
//                         marginBottom: "2rem",
//                         display: "flex",
//                         justifyContent: "center",
//                       }}
//                     >
//                       <img className="awardimg" src={award_2} alt="" />
//                     </Col>
//                     <Col
//                       style={{
//                         marginBottom: "2rem",
//                         display: "flex",
//                         justifyContent: "center",
//                       }}
//                     >
//                       <img className="awardimg" src={award_3} alt="" />
//                     </Col>
//                   </Row>
//                   <Row style={{ marginTop: "0.5rem" }}>
//                     <Col
//                       style={{
//                         marginBottom: "2rem",
//                         display: "flex",
//                         justifyContent: "center",
//                       }}
//                     >
//                       <img className="awardimg" src={award_4} alt="" />
//                     </Col>
//                     <Col
//                       style={{
//                         marginBottom: "2rem",
//                         display: "flex",
//                         justifyContent: "center",
//                       }}
//                     >
//                       <img className="awardimg" src={award_5} alt="" />
//                     </Col>
//                     <Col style={{ display: "flex", justifyContent: "center" }}>
//                       <img className="awardimg" src={award_6} alt="" />
//                     </Col>
//                   </Row>
//                   {/* <Row style={{ marginTop: "1.5rem" }}>
//                     <Col>
//                       <img className="awardimg" src={award_7} />
//                     </Col>
//                     <Col>
//                       <img className="awardimg" src={award_8} />
//                     </Col>
//                     <Col>
//                       <img className="awardimg" src={award_9} />
//                     </Col>
//                   </Row>
//                   <Row style={{ marginTop: "1.5rem" }}>
//                     <Col>
//                       <img className="awardimg" src={award_10} />
//                     </Col>
//                   </Row> */}
//                 </Col>

//                 <div style={{ marginTop: "2rem", marginBottom: "1.5rem" }}>
//                   <p style={{ fontSize: "1.5rem" }}>
//                     <b>Students' Achievements</b>
//                   </p>
//                 </div>

//                 <Row>
//                   <Col className="middlee" style={{ marginBottom: "2rem" }}>
//                     <div className="acscard">
//                       <img className="acsimg" src={achievmentsimg} />
//                       <p className="ac">
//                         <b>
//                           Won Nainital Football Tournament conducted by
//                           Amarujala Haldwani
//                         </b>
//                       </p>
//                       <p className="acdetails">Ganesh Chetri - Ist position</p>
//                     </div>
//                   </Col>
//                 </Row>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }
// export default Achievements;

import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import "./Content.css";
import "./Achievement.css"
import achTopIcon from "../../image/newImg/ach.png";
import award_1 from "../../image/award/award_1.jpg";
import award_2 from "../../image/award/award_2.jpg";
import award_3 from "../../image/award/award_3.jpg";
import award_4 from "../../image/award/award_4.jpg";
import award_5 from "../../image/award/award_5.jpg";
import award_6 from "../../image/award/award_6.jpg";
import award7 from "../../image/award/award7.jpg";
import achievmentsimg from "../../image/achievments.jpg";
import { Col, Row } from "react-bootstrap";

function Achievements(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    } else if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };

  const handleClick = () => {
    setIsShow(!isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div className="sidebar_container">
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div className="main_content">
          <div className="header">
            <img className="admissions_image" src={achTopIcon} alt="Achievements Icon" />
            <h1>Achievements & Laurels</h1>
          </div>
          <div className="intro">
            <p className="intro_text">
              "The harder you work for something, the greater you'll feel when you achieve it." It is a jubilant & ecstatic moment for Herman Gmeiner School to announce that our school has been nominated for the Global Awards for its astounding innovation in Pedagogy.
            </p>
            <div className="achievement_section">
              <p className="section_title">School’s Achievements</p>
              <div className="achievement_content">
                <div className="achievement_text">
                  Take pride in how far we have come and have faith in how far we can go. Congratulations are in order!! A proud moment for Hermann Gmeiner School as the school has once again been awarded, and this time RANKED No. 1 IN NAINITAL With a Certificate of Achievement in "INDIA’S TOP DAY CUM BOARDING SCHOOL”, by Education Today. This award brings honor and opens the door to more success in the future. This is a much-deserved award for a school that believes in consistent hard work and pursues excellence. It is inspirational to witness hard work giving birth to success. The school has been recognized with such a prestigious award through the combined efforts of the teachers and students. May we keep climbing new heights of success!
                </div>
                <div className="achievement_image">
                  <img src={award7} alt="Award" />
                </div>
              </div>
            </div>
            <div className="award_gallery">
              <p className="section_title">Awards Gallery</p>
              <Row>
                {[award_1, award_2, award_3, award_4, award_5, award_6].map((award, index) => (
                  <Col key={index} className="award_col">
                    <img className="awardimg" src={award} alt={`Award ${index + 1}`} />
                  </Col>
                ))}
              </Row>
            </div>
            <div className="students_achievements">
              <p className="section_title">Students' Achievements</p>
              <Row>
                <Col className="acscard">
                  <img className="acsimg" src={achievmentsimg} alt="Student Achievement" />
                  <p className="ac">
                    <b>Won Nainital Football Tournament conducted by Amarujala Haldwani</b>
                  </p>
                  <p className="acdetails">Ganesh Chetri - 1st position</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Achievements;

